import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";


import FavoriteIcon from '@mui/icons-material/Favorite';

import music from "../images/music.png"
import nasi from "../images/3.jpg"
import muz from "../video/nikos.mp3"


import { red } from "@mui/material/colors";

import CardLocal from "../cardLocal";


import FormSection from "../FormSection";
import Gallery from "./Gallery";
import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import Slide from "./Slide";
import Alert from "./Alert";


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 500 ? item.imgdesktop : item.imgmobil} height={window.innerWidth >= 500 ? '650px' : '600px'} opacity=".5" >
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '30px' }} >
                                <h1 style={{ fontSize: '60px' }}>{item.mire} & {item.mireasa}</h1>
                                <h4 style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}

<div className="container" style={{ maxWidth: '600px', padding: '30px' }}>
<Timer />
</div>
            <div className="text-center mt-4 pt-4">
                <span className="text-center">
                    
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} />
                    <audio src={muz} controls autoPlay loop ></audio>
                </span>
            </div>



            <BlogSectionNoi />

            <Slide />

            {data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 500 ? item.imgdesktop : item.imgmobil} height="650px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute', color: '#4F4F4F', textShadow: '1px 1px 2px white' }}>
                            <div className="col-12 " style={{ maxWidth: '650px', padding: '30px' }}>
                                <h2 >{item.title}</h2>

                                <img className="nasi" src={nasi} />
                                <h3 className="mb-4">{item.message}</h3>
                                <FavoriteIcon sx={{ color: red[800], fontSize: 40 }} className="animate__animated animate__pulse animate__delay-4s animate__infinite" />
                                <h4 className="mb-4" style={{ fontSize: '30px' }} >{item.nasii}</h4>
                                <h1 style={{ fontSize: '50px' }}>{item.nasii_nume}</h1>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



            {/*<Gallery />*/}
            

            <CardLocal />

            <BlogSectionNasii />

            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={item.img} height="400px" opacity=".2">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '650px', padding: '30px' }}>
                                <h1 style={{ color: 'green', textShadow: '1px 1px 2px white' }}>{item.title}</h1>
                                <FavoriteIcon sx={{ color: red[800], fontSize: 40 }} />
                                <h4 className="mb-4">{item.message}</h4>

                            </div>
                        </div>
                    </Parallaximg>
                )
            })}







            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.mire} & {item.mireasa}
                        </h3>
                    </div>

                )
            })}



            {/*<Alert/>*/}

        </ParallaxProvider>



    )
}

export default Home;