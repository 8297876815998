import restaurant from "./images/bal.png";
import img_card from "./images/3n.jpg";
import imgsuf from "./images/roz.jpg";
import imgsufm from "./images/roz.jpg";
import imgheader from "./images/1h.jpg";
import imgheaderm from "./images/5.jpg";
import img2 from "./images/dress2.jpg";
import imgheadermiini from "./images/restaurant1.jpeg";
import imgheadermiinimb from "./images/restaurant1.jpeg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Iurie",
        mireasa: "Carolina",
        data: "9 august 2023",
        data_confirmare: "20 iulie 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "carolinagortolomei8@gmail.com", 
       tel: "+37368621600",
       phone: "tel:+37368621600",
       viber: "viber://chat?number=%2B37368621600",
       whatsapp: "https://wa.me/+37368621600",
       messenger: "https://www.messenger.com/t/carolina.gortolomei.3",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "BallRoom Poema",
            data: "9 august 2023, miercuri, ora 17:00",
            adress: "str. Ștefan cel Mare și Sfânt 3, s. Horești, r-l. Ialoveni",
            harta: "https://goo.gl/maps/wns2QseuuGGVfZDB9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10921.29513532234!2d28.892778!3d46.8176248!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c99beb4a640dfd%3A0x47c9e08a6a653e6!2sBallRoom%20Poema!5e0!3m2!1sro!2s!4v1678170944608!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "P.S.: Am aprecia enorm dacă a-ți înlocui florile cu o carte sau o sticlă de vin.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Dress code:",
            title2: "În nuanțe pastelate.",
            message: "Vom aprecia respectarea codului vestimentar.",
            dress: "",
            message2: "" ,
            message1: "Mulțumim!" 
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgsuf,
            imgmobil: imgsufm,
            title: "NAȘII SUNT SINGURA FAMILIE PE CARE O ALEGI ȘI NOI I-AM ALES PE CEI MAI BUNI!",
            message: "Ne vor susține și ne vor îndruma:",
            nasii: "NAȘII",
            nasii_nume: "Ion & Mariana",
        }
    ],
    blogDataDrum: [
        {
            img: imgheadermiini,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;