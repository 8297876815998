import React from "react";
import data from "./Data"
import Blog from "./components/Blog";
import dress from '../src/images/dress2.jpg'
const BlogSection = (props) => {
    return (
        <section className="blog pt-5"  >
           
            
                
                        <div className="container blog-container mt-4">
        <div className="row main-row px-3">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={dress} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
              <h5 className="text-center">
              Dress code:
              </h5>
              
              <h4 className="text-center" style={{fontFamily: 'Dancing Script', fontSize: '30px'}}>
              Vom aprecia respectarea codului vestimentar.
              </h4>
<h2 className="text-center p-2">
              În aceste culori, preferabil și alte nuanțe ale lor:
              </h2>
             
              <ul className="blog-dress text-center pt-2">
                <li style={{background: '#D0C8C6'}}> </li>
                <li style={{background: '#D2BFBB'}}></li>
                <li style={{background: '#798FA4'}}></li>
                <li style={{background: '#A3A2A8'}}></li>
                <li style={{background: '#313330'}}></li>
              </ul>
              <h4 className="text-center pb-5" style={{fontFamily: 'Dancing Script', fontSize: '30px'}}>
              Mulțumim!
              </h4>
             
            </div>
          </div>
        </div>
      </div>
                 

           
        </section>


    )


}

export default BlogSection;